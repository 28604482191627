import { render, staticRenderFns } from "./MpGenre.vue?vue&type=template&id=2d83c29c&"
import script from "./MpGenre.vue?vue&type=script&lang=js&"
export * from "./MpGenre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QSkeleton,QSpinnerHourglass} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSkeleton,QSpinnerHourglass})
