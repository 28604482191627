//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import Movies from "@/components/Movies";
import { mp_genre_service } from "@/services/";
export default {
  name: "mp-genre",
  components: {
    BaseLayout,
    Movies,
  },
  metaInfo: {
    title: "MP Genre",
  },
  data() {
    return {
      movies: [],
      genre: {},
      loading: false,
    };
  },
  computed: {
    genre_id() {
      return this.$route.params.genre;
    },
  },
  mounted() {
    this.loading = true;
    this.fetch_movies();
    this.fetch_genre();
  },
  methods: {
    fetch_genre() {
      mp_genre_service
        .get({}, this.genre_id)
        .then((data) => {
          this.genre = data;
        })
        .catch((error) => {
          console.log(error);
          this.genre = { name: "No Such Genre" };
        });
    },
    fetch_movies() {
      mp_genre_service
        .get({}, `${this.genre_id}/movies`)
        .then((data) => {
          this.movies.splice(0, this.movies.length);
          this.movies.push(...data.results);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    on_select(movie) {
      this.$router.push({
        name: "movie-detail",
        params: { id: movie.id, slug: this.slugify(movie.title) },
      });
    },
  },
};
